import { Grid } from '@audentio/stuff/src';
import { Layout } from 'components/Layout';
import { PrincipleBenefits } from 'components/Principle/Benefits';
import { PrincipleFeatures } from 'components/Principle/Features';
import { PrinciplesNav } from 'components/PrinciplesNav';
import { graphql } from 'gatsby';
import React from 'react';
import { LandingFeatureGrid } from 'src/components/Landing/LandingFeatureGrid';
import {
    ClientHighlight,
    StatBox,
} from 'src/components/Methods/metrics/ClientHighlight';
import { FeatureHighlight } from 'src/components/Principle/FeatureHighlight';
import { PrincipleHero } from 'src/components/Principle/Hero';
export default function MonetizationPage({ data }) {
    return (
        <Layout
            pageTitleFull="Monetization: An Audentio Community Principle"
            siteDescription="Generate the revenue you need to meet your financial goals. Contact us for help monetizing your online community!"
            contactForm
            subnavTitle="Monetization"
            subnav={[
                {
                    id: 'features',
                    label: 'Features',
                },

                {
                    id: 'membership',
                    label: 'Membership',
                },
                {
                    id: 'benefits',
                    label: 'Benefits',
                },
                {
                    id: 'advertisement',
                    label: 'Advertisements',
                },
            ]}
        >
            <PrincipleHero
                title="Redefine and discover %brevenue streams"
                subtitle="Monetization"
                image={data.hero.childImageSharp.fluid}
            />

            <PrinciplesNav name="monetization" />

            <PrincipleFeatures
                id="features"
                title="increase revenue"
                features={[
                    {
                        subtitle: 'revenue',
                        title: 'membership models',
                        content:
                            'Create different tiers unique to your community for premium membership.',
                    },
                    {
                        subtitle: 'network',
                        title: 'sponsor content',
                        content:
                            'Your niche might benefit from sponsorship. Utilize sponsors with links to your community to offset costs.',
                    },
                    {
                        subtitle: 'experience',
                        title: 'introduce digital currency',
                        content:
                            'Giving your community a currency to utilize encourages them to continue to be active, contributing members.',
                    },
                    {
                        subtitle: 'revenue',
                        title: 'donation models',
                        content:
                            'Crowdfunding allows members of your community to invest in the community itself.',
                    },
                    {
                        subtitle: 'network',
                        title: 'integrating advertisements',
                        content:
                            'Set up ads that are incorporated into the design and maintain a good user experience.',
                    },
                    {
                        subtitle: 'experience',
                        title: 'open a shop',
                        content:
                            'Whether physical or digital, let your community purchase something or select a reward for themselves through you.',
                    },
                ]}
            />

            {/* <CTA
                title="Ready to build a community?"
                buttonText="Contact us"
                href="/contact?source=monetization"
            /> */}

            <FeatureHighlight
                bg="secondaryBackground"
                id="membership"
                py={['40px', 'spacing-xl', '80px']}
                mb={0}
                subtitle="Revenue"
                image={data.memberModel.childImageSharp.fluid}
                title="A membership model allows for exclusive, quality content"
                highlights={[
                    {
                        title: 'create an exclusive members-only area',
                        content: 'Share unique content with paid members.',
                    },
                    {
                        title: 'reward your supporters with unique features',
                        content:
                            'Special badges, exclusive themes and an ad-free experience.',
                    },
                    {
                        title:
                            'provide early access and build a test commmunity',
                        content:
                            'Paid members can test new features, providing additional value.',
                    },
                ]}
            />

            <ClientHighlight
                title="Case study"
                bg="primaryBackground"
                image={data.graph.childImageSharp.fluid}
                description="For a client, we introduced a full strategy plan that included updating the subscription model, adding a donation campaign, hosting several contests, and adding gamification."
                pt={[0, 0, 0]}
                alt="Graph showing the results on the implemented strategy"
                gridSectionProps={{
                    borderTop: 'none',
                }}
            >
                <Grid
                    columns={{
                        _: 2,
                        lg: 1,
                    }}
                    spacing={{
                        _: 'spacing-lg',
                        lg: 'spacing-xl',
                    }}
                    w={{
                        _: '100%',
                        lg: 250,
                    }}
                >
                    <StatBox
                        title="99%"
                        subtitle="Increase in"
                        kind="Earnings"
                    />
                    <StatBox
                        title="47%"
                        subtitle="Increase in"
                        kind="User registrations"
                    />
                </Grid>
            </ClientHighlight>

            <PrincipleBenefits
                mt={0}
                id="benefits"
                title="How monetization can benefit your community"
                // ctaHref="/contact?source=monetization"
                // ctaLabel="Contact us"
                benefits={[
                    'help achieve financial goals',
                    'discover new revenue streams',
                    'manage advertising properly',
                ]}
            />

            {/* <MediaSection
                id="advertisement"
                title="Take a hassle-free approach to advertisements, we’ll handle it"
                image={data.ads.childImageSharp.fluid}
            >
                Advertising can be an important source of revenue yet
                challenging to implement seamlessly. We’ll take on the task by
                working with your ad team on a spec, consulting on how to
                maintain a good user experience, designing layouts with ad
                placement in mind, and more.
            </MediaSection> */}

            <LandingFeatureGrid
                center
                offset={false}
                flexDirection="column"
                title="Related articles"
                sectionHeaderProps={{
                    direction: 'row',
                }}
                customButton={{
                    text: 'See More',
                    href: 'https://adjourn.audent.io/',
                }}
                showContact={false}
                items={[
                    {
                        image: data.live.childImageSharp.fluid,
                        heading:
                            'A look into what we’re doing for communities with streaming',
                        content:
                            'Here at Audentio, the last couple of years for us have been so critical in building our future.Thousands of hours have been poured into...',
                        href:
                            'https://adjourn.audent.io/a-look-into-what-were-doing-for-communities-with-streaming-aaa47d94cdb2',
                    },
                    {
                        image: data.effectiveMonetization.childImageSharp.fluid,
                        heading: 'How to effectively monetize your community',
                        content:
                            'Most communities cost money to maintain; hard costs such as platforms & hosting for your community are obvious. Other costs can include...',
                        href:
                            'https://adjourn.audent.io/how-to-effectively-monetize-your-community-9c4d52aee9e2',
                    },
                    {
                        image: data.donationModal.childImageSharp.fluid,
                        heading: 'How to create a successful donation model',
                        content:
                            'Starting a growing community can oftentimes be expensive. We share our methods on implementing subscriptions models, offering merchandise, running ads, and more.',
                        href:
                            'https://adjourn.audent.io/how-to-create-a-successful-donation-model-84130a596a7a',
                    },
                ]}
            />
        </Layout>
    );
}

export const query = graphql`
    query MonetizationQuery {
        hero: file(relativePath: { eq: "images/hero/monetization.jpg" }) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        memberModel: file(
            relativePath: { eq: "images/monetization/membership-model.png" }
        ) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        # ads: file(relativePath: { eq: "images/monetization/a-manage.png" }) {
        #     childImageSharp {
        #         fluid {
        #             ...GatsbyImageSharpFluid_withWebp
        #         }
        #     }
        # }
        effectiveMonetization: file(
            relativePath: { eq: "images/monetization/effective-money.png" }
        ) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        live: file(relativePath: { eq: "images/monetization/live.png" }) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        donationModal: file(
            relativePath: { eq: "images/monetization/donation-modal.png" }
        ) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        graph: file(
            relativePath: {
                eq: "images/monetization/monetize-metric-graph.png"
            }
        ) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
    }
`;
